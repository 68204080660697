import Constants from 'Constants'
import Common from '@/assets/js/common.js'
import VatTransformer from '@/assets/js/dexie/transformers/VatTransformer'
import ContractTransformer from '@/assets/js/dexie/transformers/ContractTransformer.js'
import ContractAvenantArticlesTransformer from '@/assets/js/dexie/transformers/ContractAvenantArticlesTransformer.js'
import InvoiceTransformer from '@/assets/js/dexie/transformers/InvoiceTransformer.js'

const InvoiceMixin = {
	data() {
		return {

		}
	},
	methods: {
		// Récupère un article
		loadInvoice: async function(from=null, to=null) {
			from = from ? from.toDateInputValue() : ''
			to = to ? to.toDateInputValue() : ''
			const url = `${this.constructRoute(Constants.INVOICES_URL, {})}?licence_key=${Constants.USER_LICENCE_KEY}&from=${from}&to=${to}`
			const result = await this.$request.request_get_api("InvoiceMixin::loadInvoice", url)
			if(result) return result.retour
			return null
		},

		loadInvoiceHorse: async function(horse_id, from=null, to=null) {
			from = from ? from.toDateInputValue() : ''
			to = to ? to.toDateInputValue() : ''
			const url = `${this.constructRoute(Constants.HORSE_INVOICES_URL, {horse_id: horse_id})}?licence_key=${Constants.USER_LICENCE_KEY}&from=${from}&to=${to}`
			const result = await this.$request.request_get_api("InvoiceMixin::loadInvoiceHorse", url)

			if(result) return result.retour
			return null
		},

		loadInvoiceContract: async function(contract_id) {
			const url = `${this.constructRoute(Constants.CONTRACT_INVOICES_URL, {contract_id: contract_id})}?licence_key=${Constants.USER_LICENCE_KEY}`
			const result = await this.$request.request_get_api("InvoiceMixin::loadInvoiceContract", url)

			if(result) return result.retour
			return null
		},

		loadAllBons: async function(options) {
			const url = this.constructRoute(Constants.INVOICES_BONS_URL, options) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("InvoiceMixin::loadAllBons", url)
			if(result) return result.retour
			return null
		},

		loadAllBonsv2: async function(options) {
			const url = this.constructRoute(Constants.INVOICES_BONS_V2_URL, options) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("InvoiceMixin::loadAllBons", url)
			if(result) return result.retour
			return null
		},

		sendErrorSlack: async function(length_v1, length_v2) {
			const params = {
				l1: length_v1,
				l2: length_v2
			}

			const url = this.constructRoute(Constants.INVOICES_BONS_ERREUR_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			this.$request.request_get_api("InvoiceMixin::sendErrorSlack", url)
		},

		loadAllBonsByTiers: async function(tiers_id) {
			const url = this.constructRoute(Constants.INVOICES_BONS_BY_TIERS_URL, {tiers_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("InvoiceMixin::loadAllBonsByTiers", url)
			if(result) return result.retour
			return null
		},

		getLastInvoiceDateByAuthor: async function(author_id) {
			const url = this.constructRoute(Constants.LAST_INVOICE_BY_ENTITY_URL, {author_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("InvoiceMixin::getLastInvoiceDateByAuthor", url)
			if(result) return result.retour
			return null
		},

		createInvoice: async function(params) {
			const url = Constants.INVOICES_CREATE_URL + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("InvoiceMixin::createInvoice", url, params, false)
			.catch(error => {
				console.error("InvoiceMixin::createInvoice => ERROR", error)
				if(error.response.data.message != "")
				{
					this.failureToast(error.response.data.message)
				}
				else
				{
					this.failureToast("toast.info_save_failed")
				}
				return null
			})
			
			return result
		},

		createInvoiceDetails: async function(params) {
			const url = Constants.INVOICES_DETAILS_CREATE_URL + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("InvoiceMixin::createInvoiceDetails", url, params, false, {withDetails: true})
			.catch(error => {
				console.error("InvoiceMixin::createInvoiceDetails => ERROR", error)
				if(error.data.message != "") {
					this.failureToast(error.data.message)
				}
				else {
					this.failureToast("toast.info_save_failed")
				}
				return null
			})
			
			return result
		},

		createMultipleInvoiceDetails: async function(params) {
			const url = Constants.INVOICES_DETAILS_CREATE_MULTIPLE_URL + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("InvoiceMixin::createInvoiceDetails", url, params, false, {withDetails: true})
			.catch(error => {
				console.error("InvoiceMixin::createInvoiceDetails => ERROR", error)
				if(error.data.message != "") {
					this.failureToast(error.data.message)
				}
				else {
					this.failureToast("toast.info_save_failed")
				}
				return null
			})
			
			return result
		},

		abandonAValider: async function(params) {
			const url = Constants.INVOICES_ABANDON_A_VALIDER_URL + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("InvoiceMixin::abandonAValider", url, params)
			.catch(error => {
				console.error("InvoiceMixin::abandonAValider => ERROR", error)
				if(error.data.message != "") {
					this.failureToast(error.data.message)
				}
				else {
					this.failureToast("toast.info_save_failed")
				}
				return null
			})
			
			return result
		},

		checkCreditDate: async function(date, author_id) {
			const url = this.constructRoute(Constants.INVOICES_CREDIT_DATE_CHECK, {invoice_date: date, author_id: author_id})+ "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("InvoiceMixin::checkCreditDate", url)
			.catch(error => {
				console.error("InvoiceMixin::checkCreditDate => ERROR", error)
				if(error.response.data.message != "")
				{
					this.failureToast(error.response.data.message)
				}
				else
				{
					this.failureToast("toast.info_save_failed")
				}
				return null
			})
			
			return result
		},

		getBalanceInvoices: async function(invoice_ids) {
			const url = this.constructRoute(Constants.INVOICES_BALANCE_URL, {invoice_ids: invoice_ids}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("InvoiceMixin::getBalanceInvoices", url)
			.catch(error => {
				console.error("InvoiceMixin::getBalanceInvoices => ERROR", error)
				this.failureToast("toast.info_save_failed")
				return null
			})
			
			return result
		},

		updateInvoiceDetails: async function(params, invoicedetails_id) {
			const url = this.constructRoute(Constants.INVOICES_DETAILS_UPDATE_URL, {invoicedetails_id: invoicedetails_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("InvoiceMixin::updateInvoiceDetails", url, params, false)
			.catch(error => {
				console.error("InvoiceMixin::updateInvoiceDetails => ERROR", error)
				this.failureToast("toast.info_save_failed")
				return null
			})
			
			return result
		},

		saveOrderInvoiceDetails: async function(params) {
			const url = this.constructRoute(Constants.INVOICES_DETAILS_UPDATE_ORDER_URL, {}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("InvoiceMixin::saveOrderInvoiceDetails", url, {lignes: params}, false)
			.catch(error => {
				console.error("InvoiceMixin::saveOrderInvoiceDetails => ERROR", error)
				this.failureToast("toast.info_save_failed")
				return null
			})
			
			return result
		},

		deleteInvoiceDetails: async function(params) {
			const url = this.constructRoute(Constants.INVOICES_DETAILS_DELETE_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_delete_api("InvoiceMixin::deleteInvoiceDetails", url)
			.catch(error => {
				console.error("InvoiceMixin::deleteInvoiceDetails => ERROR", error)
				this.failureToast("toast.info_delete_failed")
				return null
			})
			
			return result
		},
		dedoublonnerInvoiceDetails: async function(params) {
			const url = this.constructRoute(Constants.INVOICES_DETAILS_DEDOUBLONNER_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_delete_api("InvoiceMixin::dedoublonnerInvoiceDetails", url)
			.catch(error => {
				console.error("InvoiceMixin::dedoublonnerInvoiceDetails => ERROR", error)
				this.failureToast("toast.info_delete_failed")
				return null
			})
			
			return result
		},

		getRecheckVat: async function(params) {
			const url = Constants.INVOICES_DETAILS_VAT_RECHECK_URL + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("InvoiceMixin::getRecheckVat", url, {invoicedetails_id: params}, false)
			.catch(error => {
				console.error("InvoiceMixin::getRecheckVat => ERROR", error)
				this.failureToast("toast.info_delete_failed")
				return null
			})
			
			return result
		},

		previewInvoice: async function(params, preview = true) {
			const url = Constants.INVOICES_DETAILS_PREVIEW_URL + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("InvoiceMixin::previewPdf", url, params, false)
			if(preview)
			{
				Common.base64ToPdf(result, "preview.pdf")
			}

			return result
		},
		addRelanceManuelle: async function(invoice_ids, date, message, type = "autre") {
			const url = Constants.INVOICES_RELANCE_MANUELLE_URL + "?licence_key="+Constants.USER_LICENCE_KEY

			const params = {
				type,
				invoice_ids,
				date,
				message,
			}

			const result = await this.$request.request_post_api("InvoiceMixin::addRelanceManuelle", url, params, false)
			.catch(error => {
				console.error("InvoiceMixin::addRelanceManuelle => ERROR", error)
				this.failureToast("toast.info_save_failed")
				return null
			})
			return result
		},
		duplicateInvoice: async function(invoice_ids) {
			const url = this.constructRoute(Constants.INVOICES_DUPLICATE_URL, {invoice_ids: invoice_ids}) + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("InvoiceMixin::duplicateInvoice", url, {}, false)
			.catch(error => {
				console.error("InvoiceMixin::duplicateInvoice => ERROR", error)
				this.failureToast("toast.info_save_failed")
				return null
			})
			return result	
		},

		pdfInvoice: async function(invoice_ids, invoice_nums, base64=false) {
			let tab_invoice_nums = invoice_nums.split(",")

			const url = this.constructRoute(Constants.INVOICES_PDF_URL, {invoice_ids: invoice_ids}) + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("InvoiceMixin::pdfInvoice", url, {}, false)
			.catch(error => {
				console.error("InvoiceMixin::pdfInvoice => ERROR", error)
				this.failureToast("toast.info_save_failed")
				return null
			})

			if(result && !base64) {
				Common.base64ToPdf(result, tab_invoice_nums.join('-') + ".pdf")
			}
			else {
				return result
			}
		},

		pdfInvoiceWithAdvancedPayment: async function(invoice_ids, invoice_nums, base64=false) {
			let tab_invoice_nums = invoice_nums.split(",")
			const params = {
				acompte: true
			}

			const url = this.constructRoute(Constants.INVOICES_PDF_URL, {invoice_ids: invoice_ids}) + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("InvoiceMixin::pdfInvoiceWithAdvancePayment", url, params, false)
			.catch(error => {
				console.error("InvoiceMixin::pdfInvoiceWithAdvancePayment => ERROR", error)
				this.failureToast("toast.info_save_failed")
				return null
			})

			if(result && !base64) {
				Common.base64ToPdf(result, tab_invoice_nums.join('-') + ".pdf")
			}
			else {
				return result
			}
		},

		downloadPdfInvoice: async function(invoice_ids) {

			const url = this.constructRoute(Constants.INVOICES_DOWNLOAD_PDF_URL, {invoice_ids: invoice_ids}) + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("InvoiceMixin::downloadPdfInvoice", url, {}, false)
			.catch(error => {
				console.error("InvoiceMixin::downloadPdfInvoice => ERROR", error)
				this.failureToast("toast.info_save_failed")
				return null
			})

			if(result) {
				for (const key in result) {
					Common.base64ToPdf(result[key], key + ".pdf")
				}
			}			
		},

		loadBilan: async function(start_date, end_date) {
			const url = this.constructRoute(Constants.INVOICES_BILAN_URL, {"start_date": start_date, "end_date": end_date}) + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("InvoiceMixin::loadBilan", url)
			if(result) return result.retour
			return null
		},

		loadArticlesCourse: async function(start_date, end_date) {
			const url = this.constructRoute(Constants.INVOICES_ARTICLES_COURSE_URL, {"start_date": start_date, "end_date": end_date}) + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("InvoiceMixin::loadArticlesCourse", url)
			if(result) return result.retour
			return null
		},

		loadFacturationAValider: async function(start_date, end_date, display_zero, display_abandonne) {
			const url = this.constructRoute(Constants.INVOICES_FACTURATION_A_VALIDER_URL, {"start_date": start_date, "end_date": end_date, "display_zero": display_zero, "display_abandonne": display_abandonne}) + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("InvoiceMixin::loadFacturationAValider", url)
			if(result) return result.retour
			return null
		},
		loadFacturationAValiderByTiers: async function(tiers_id, start_date, end_date, display_zero, display_abandonne) {
			const url = this.constructRoute(Constants.INVOICES_A_VALIDER_BY_TIER_URL, {"tiers_id": tiers_id, "start_date": start_date, "end_date": end_date, "display_zero": display_zero, "display_abandonne": display_abandonne}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("InvoiceMixin::loadFacturationAValider", url)
			if(result) return result.retour
			return null
		},

		loadFacturationAValiderSignature: async function(season_id) {
			const url = this.constructRoute(Constants.INVOICES_FACTURATION_SIGN_URL, {season_id}) + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("InvoiceMixin::loadFacturationAValiderSignature", url)
			if(result) return result.retour
			return null
		},

		loadFacturationAValiderMonteSaillie: async function(season_id) {
			const url = this.constructRoute(Constants.INVOICES_FACTURATION_SAILLIE_URL, {season_id}) + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("InvoiceMixin::loadFacturationAValiderMonteSaillie", url)
			if(result) return result.retour
			return null
		},

		loadFacturationAValiderMontePoulain: async function(season_id) {
			const url = this.constructRoute(Constants.INVOICES_FACTURATION_POULAIN_URL, {season_id}) + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("InvoiceMixin::loadFacturationAValiderMontePoulain", url)
			if(result) return result.retour
			return null
		},

		loadFacturationAValiderMonteOctobre: async function(season_id) {
			const url = this.constructRoute(Constants.INVOICES_FACTURATION_OCTBR_URL, {season_id}) + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("InvoiceMixin::loadFacturationAValiderMonteOctobre", url)
			if(result) return result.retour
			return null
		},

		getUnpaidInvoices: async function(start_date,end_date) {
			start_date = start_date ? start_date.toDateInputValue() : ''
			end_date = end_date ? end_date.toDateInputValue() : ''
			
			const url = this.constructRoute(Constants.INVOICES_UNPAID_URL, {"start_date": start_date, "end_date": end_date}) + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("InvoiceMixin::getUnpaidInvoices", url)
			if(result && result.retour) return result.retour
			return null
		},

		calculateInvoice: async function(params) {

			const url = Constants.INVOICES_CALCUL_URL + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("InvoiceMixin::calculateInvoice", url, params, false)
			.catch(error => {
				console.error("InvoiceMixin::calculateInvoice => ERROR", error)
				this.failureToast("toast.info_save_failed")
				return null
			})

			return result.retour

		},

		getInvoiceDetail: async function(invoice_id) {
			const url = this.constructRoute(Constants.INVOICES_DETAILS, {invoice_id: invoice_id}) + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("InvoiceMixin::getInvoiceDetail", url)
			if(result) return result.retour
			return null
		},

		getVat: async function() {
			return this.$storage.db.t('vat')
			.then(table => {
				return table.orderBy('vat_value')
			})
			.then(col => {
				return col.transform(new VatTransformer())
			})
		},

		createCredit: async function(params) {
			const url = Constants.INVOICES_CREDIT + "?licence_key="+Constants.USER_LICENCE_KEY
			
			const result = await this.$request.request_post_api("InvoiceMixin::createCredit", url, params, false)
			.catch(error => {
				console.error("InvoiceMixin::createCredit => ERROR", error)
				this.failureToast("toast.info_save_failed")
				return null
			})

			return result.retour
		},

		getCreditInvoice: async function(invoice_id) {
			const url = this.constructRoute(Constants.INVOICES_CREDIT_GET, {invoice_id: invoice_id}) + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("InvoiceMixin::getCreditInvoice", url)
			.catch(error => {
				console.error("InvoiceMixin::getCreditInvoice => ERROR", error)
				this.failureToast("toast.info_save_failed")
				return null
			})

			return result.retour
		},

		sendMailInvoices: async function(invoice_ids, model_type = "invoice_notification", model_id = null, commentaire = '', expeditor = null, send_mail_sender/*, email_content, set_copie*/) {
			const url = Constants.INVOICES_SEND + "?licence_key="+Constants.USER_LICENCE_KEY

			const params = {
				invoice_ids: invoice_ids.split(","),
				model_type: model_type,
				model_id: model_id,
				commentaire: commentaire,
				expeditor: expeditor,
				send_mail_sender: send_mail_sender,
				/*email_content: email_content,
				set_copie: set_copie*/
			}
			
			const result = await this.$request.request_post_api("InvoiceMixin::sendMailInvoices", url, params, false)
			.catch(error => {
				console.error("InvoiceMixin::sendMailInvoices => ERROR", error)
				return null
			})

			return result ? result.retour : null
		},

		loadExportComptable: async function(invoices_ids) {
			const url = Constants.INVOICES_EXPORT_COMPTABLE + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("InvoiceMixin::loadExportComptable", url, {params: {invoice_ids: invoices_ids}}, false)
			.catch(error => {
				console.error("InvoiceMixin::loadExportComptable => ERROR", error)
				this.failureToast("toast.info_save_failed")
				return null
			})

			Object.keys(result).forEach(function (item) {
				Common.csvToFile(result[item]['lines'], "export_comptable_" + item + "." + result[item]['extension'])
			});

        },
        
        getAddressedInvoicesToPay: async function() {
            const url = Constants.INVOICES_ADRESSED_TOPAY + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("InvoiceMixin::getAddressedInvoicesToPay", url)
			.catch(error => {
				console.error("InvoiceMixin::getAddressedInvoicesToPay => ERROR", error)
				return null
			})

			return result ? result.retour : null
        },

        getAddressedInvoicesSold: async function() {
            const url = Constants.INVOICES_ADRESSED_PAID + "?licence_key="+Constants.USER_LICENCE_KEY
            
			const result = await this.$request.request_get_api("InvoiceMixin::getAddressedInvoicesSold", url)
			.catch(error => {
				console.error("InvoiceMixin::getAddressedInvoicesSold => ERROR", error)
				return null
			})

			return result ? result.retour : null
        },

        getAddressedPayments: async function() {
            const url = Constants.PAIEMENTS_ADRESSED + "?licence_key="+Constants.USER_LICENCE_KEY
            
			const result = await this.$request.request_get_api("InvoiceMixin::getAddressedInvoicesSold", url)
			.catch(error => {
				console.error("InvoiceMixin::getAddressedInvoicesSold => ERROR", error)
				return null
			})

			return result ? result.retour : null
        },

        getAddressedPdfInvoice: async function(invoice_ids, invoice_nums) {
			let tab_invoice = invoice_ids.split(",")
			let tab_invoice_nums = invoice_nums.split(",")

			for (let i = 0; i < tab_invoice.length; i++) {
				const url = this.constructRoute(Constants.INVOICES_PDF_ADDRESSED, {
                    invoice_id: tab_invoice[i]
                }) + "?licence_key="+Constants.USER_LICENCE_KEY

				const result = await this.$request.request_get_api("InvoiceMixin::pdfInvoice", url)
				.catch(error => {
					console.error("InvoiceMixin::getAddressedPdfInvoice => ERROR", error)
					return null
				})

				Common.base64ToPdf(result, tab_invoice_nums[i] + ".pdf")
			}
		},

		getFactureSoldeTiers: async function(tiers_id) {
			const url = this.constructRoute(Constants.TIERS_SOLDE, {tiers_id: tiers_id}) + "?licence_key=" + Constants.USER_LICENCE_KEY
            
			const result = await this.$request.request_get_api("InvoiceMixin::getFactureSoldeTiers", url)
			.catch(error => {
				console.error("InvoiceMixin::getFactureSoldeTiers => ERROR", error)
				return null
			})

			return result ? result.retour : null
		},

		getInvoiceSuppliersList: async function(tiers_id) {
			const url = this.constructRoute(Constants.INVOICES_SUPPLIER_LIST, {
				tiers_id: tiers_id
			}) + "?licence_key="+Constants.USER_LICENCE_KEY
			
			return this.$request.request_get_api("InvoiceMixin::getInvoiceSuppliersList", url)
				.catch(e => {
					console.error("InvoiceMixin::getInvoiceSuppliersList => ERROR", e)
					return null
				})
				.then(res => {
					return res.retour
				})
		},

		getInvoiceSuppliersDetailsList: async function(invoice_id) {
			const url = this.constructRoute(Constants.INVOICES_SUPPLIER_DETAILS_LIST, {
				invoice_id: invoice_id
			}) + "?licence_key="+Constants.USER_LICENCE_KEY
			
			return this.$request.request_get_api("InvoiceMixin::getInvoiceSuppliersDetailsList", url)
				.catch(e => {
					console.error("InvoiceMixin::getInvoiceSuppliersDetailsList => ERROR", e)
					return null
				})
				.then(res => {
					return res.retour
				})
		},

		saveInvoiceSupplier: async function(params, invoice_id='') {
			const url = this.constructRoute(Constants.INVOICES_SUPPLIER_SAVE, {
				invoice_id: invoice_id || ''
			}) + "?licence_key="+Constants.USER_LICENCE_KEY
						
			return this.$request.request_post_api("InvoiceMixin::addInvoiceSupplier", url, params, false)
				.catch(e => {
					console.error("InvoiceMixin::addInvoiceSupplier => ERROR", e)
					return null
				})
				.then(res => {
					return res.retour		
				})
		},

		getInvoiceSupplier: async function(invoicesuppliers_id) {
			const url = this.constructRoute(Constants.INVOICES_SUPPLIER_GET, {
				invoice_id: invoicesuppliers_id
			}) + "?licence_key="+Constants.USER_LICENCE_KEY

			return this.$request.request_get_api("InvoiceMixin::getInvoiceSupplier", url)
				.catch(e => {
					console.error("InvoiceMixin::getInvoiceSupplier => ERROR", e)
					return null
				})
				.then(res => {
					return res.retour
				})
		},

		uploadInvoiceDocument: async function(invoice_id, file) {
			const url = this.constructRoute(Constants.INVOICES_SUPPLIER_ADD_FILE, {
				invoice_id: invoice_id
			}) + "?licence_key="+Constants.USER_LICENCE_KEY

			return this.$request.request_post_file_api("InvoiceMixin::uploadInvoiceDocument", url, [
				{ 
					name: 'document',
					content: file
				}])
				.catch(e => {
					console.error("InvoiceMixin::uploadInvoiceDocument => ERROR", e)
					return null
				})
				.then(res => {
					return res.retour
				})
		},

		getArticlesDeboursOrAvf: async function(invoice_id, entity_id) {
			const url = this.constructRoute(Constants.ARTICLES_GET_DEBOURS_AVF, {
				invoicesupplier_id: invoice_id, accountingplan_id: entity_id
			}) + "?licence_key="+Constants.USER_LICENCE_KEY

			return this.$request.request_get_api("InvoiceMixin::getArticlesDeboursOrAvf", url)
				.catch(e => {
					console.error("InvoiceMixin::getArticlesDeboursOrAvf => ERROR", e)
					return null
				})
				.then(res => {
					return res.retour
				})
		},
		documentInvoiceSupplier: async function(invoice_id){

			const url = this.constructRoute(Constants.INVOICES_SUPPLIER_DOCUMENT, {invoice_id: invoice_id}) + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("InvoiceMixin::documentInvoiceSupplier", url)
			.catch(error => {
				console.error("InvoiceMixin::documentInvoiceSupplier => ERROR", error)
				this.failureToast("toast.info_save_failed")
				return null
			})
			if(result != null)
			{
				Common.base64toBlob(result.base64, result.name)
			}
		},

		createInvoiceSuppliersDetails: async function(params) {
			const url = Constants.INVOICES_SUPPLIERS_DETAILS_CREATE_URL + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("InvoiceMixin::createInvoiceSuppliersDetails", url, params, false)
			.catch(error => {
				console.error("InvoiceMixin::createInvoiceSuppliersDetails => ERROR", error)
				this.failureToast("toast.info_save_failed")
				return null
			})
			
			return result
		},


		getVatCode: async function(author_id, tiers_id) {
			const url = this.constructRoute(Constants.INVOICES_VAT_CODE, {
				author_id: author_id,
				tiers_id: tiers_id,
			}) + "?licence_key="+Constants.USER_LICENCE_KEY
			
			return this.$request.request_get_api("InvoiceMixin::getVatCode", url)
				.catch(e => {
					console.error("InvoiceMixin::getVatCode => ERROR", e)
					return null
				})
				.then(res => {
					return res.retour.code
				})
		},

		accounted_invoices: async function (invoice_ids) {
			const params = {
				invoice_ids: invoice_ids
			}

			const url = Constants.INVOICES_ACCOUNTED_URL + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("InvoiceMixin::accounted_invoices", url, params, false)
			.catch(error => {
				console.error("InvoiceMixin::accounted_invoices => ERROR", error)
				this.failureToast("toast.info_save_failed")
				return null
			})
			return result
		},

		notPrintedInvoice: async function(invoice_ids) {
			const params = {
				invoice_ids: invoice_ids
			}

			const url = Constants.INVOICES_NOT_PRINTED_URL + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("InvoiceMixin::notPrintedInvoice", url, params, false)
			.catch(error => {
				console.error("InvoiceMixin::notPrintedInvoice => ERROR", error)
				this.failureToast("toast.info_save_failed")
				return null
			})
			return result
		},

		loadDiscountType: async function() {
			return this.$storage.db.t('invoice_discount_type')
			.then(table => {
				return table.toArray()
			})			

			// const url = Constants.INVOICES_DISCOUNT_TYPE + "?licence_key="+Constants.USER_LICENCE_KEY

			// const result = await this.$request.request_get_api("InvoiceMixin::loadDiscountType", url)
			// .catch(error => {
			// 	console.error("InvoiceMixin::loadDiscountType => ERROR", error)
			// 	this.failureToast("toast.info_save_failed")
			// 	return null
			// })
			// return result.retour
		},

		getAnalyticsCode: async function(accountingplan_tiers) {
			
			const url = this.constructRoute(Constants.ACCOUNTING_CODE_ANA_URL, {accountingplan_tiers: accountingplan_tiers}) + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("InvoiceMixin::getAnalyticsCode", url)
			.catch(error => {
				console.error("InvoiceMixin::getAnalyticsCode => ERROR", error)
				this.failureToast("toast.info_save_failed")
				return null
			})
			return result.retour
		},

		loadInvoiceStat: async function(invoice_ids) {
			const params = {
				invoice_ids: invoice_ids
			}

			const url = Constants.INVOICE_STAT_URL + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("InvoiceMixin::loadInvoiceStat", url, params, false)
			.catch(error => {
				console.error("InvoiceMixin::loadInvoiceStat => ERROR", error)
				this.failureToast("toast.info_save_failed")
				return null
			})
			return result.retour
		},

		setChangeTiers: async function(invoicedetails_ids, tiers_id) {
			const params = {
				invoicedetails_ids: invoicedetails_ids,
				tiers_id: tiers_id
			}

			const url = Constants.INVOICE_CHANGE_TIERS_URL + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("InvoiceMixin::setChangeTiers", url, params, false)
			.catch(error => {
				console.error("InvoiceMixin::setChangeTiers => ERROR", error)
				this.failureToast("toast.info_save_failed")
				return null
			})
			return result.retour
		},

		loadInvoiceBonsStat: async function(invoicedetails_ids) {
			const params = {invoicedetails_ids}

			const url = Constants.INVOICE_BONS_STAT_URL + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("InvoiceMixin::loadInvoiceBonsStat", url, params, false)
			.catch(error => {
				console.error("InvoiceMixin::loadInvoiceBonsStat => ERROR", error)
				this.failureToast("toast.info_save_failed")
				return null
			})
			return result.retour
		},

		controlProperties: async function(start_date, end_date) {
			const url = this.constructRoute(Constants.INVOICES_CONTROL_PROPERTIES_URL, {"start_date": start_date, "end_date": end_date}) + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("InvoiceMixin::loadFacturationAValider", url)
			if(result) return result.retour
			return null
		},
		getInvoicesDetailsViergeFromTiers: async function(tiers_ids, contract_id) {
			const url = this.constructRoute(Constants.INVOICES_DETAILS_VIERGE_TIERS_URL, {"tiers_ids": tiers_ids, "contract_id": contract_id}) + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("InvoiceMixin::getInvoicesDetailsViergeFromTiers", url)
			if(result) return result.retour
			return null
		},
		updateInvoiceSaillie: async function(invoice_ids) {
			const url = this.constructRoute(Constants.INVOICES_SAILLIE_URL, {}) + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("InvoiceMixin::updateInvoiceSaillie", url,  {invoice_ids: invoice_ids}, false)
			if(result) return result.retour
			return null
		},

		checkFirstInvoiceMonth: async function(date, invoicedetails_id) {
			const url = this.constructRoute(Constants.INVOICES_CHECK_MONTH_URL, {}) + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("InvoiceMixin::checkFirstInvoiceMonth", url,  {"date": date, "invoicedetails_id": invoicedetails_id}, false)
			if(result) return result.retour
			return null
		},
		goToDeleteInvoices: async function(params) {
			let nums = params.invoice_nums.split(',')
			for (let i = 0; i < nums.length; i++) {
				if(nums[i] != "") {
					this.failureToast("invoice.no_delete_invoice")
					return false
				}
			}

			const url = this.constructRoute(Constants.INVOICES_DELETE_URL, {invoice_ids: params.invoice_ids}) + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_delete_api("InvoiceMixin::goToDeleteInvoices", url)
			if(result) return result.retour
			return null
		},

		goToDeleteSupplierInvoice: async function(invoice_id) {

			const url = this.constructRoute(Constants.INVOICES_SUPPLIER_DELETE_URL, {invoice_id: invoice_id}) + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_delete_api("InvoiceMixin::goToDeleteInvoicesSuppliers", url)
			if(result) return result.retour
			return null
		},

		async getInvoiceById(invoice_id) {
			const url = this.constructRoute(Constants.INVOICE_GET_URL, {invoice_id}) + "?licence_key=" + Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("InvoiceMixin::getInvoiceById", url)
			if(result) return result.retour
			return null
		},

		async soldeAvoir(invoice_id) {
			const url = this.constructRoute(Constants.INVOICES_SOLDE_AVOIR_URL, {invoice_id}) + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("InvoiceMixin::soldeAvoir", url, {}, false)
			if(result) return result.retour
			return null
		},

		async getInvoiceSupplierControl(entity_ids) {
			const url = this.constructRoute(Constants.INVOICES_SUPPLIERS_OCR_LIST_CONTROL, {entity_ids}) + "?licence_key=" + Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("InvoiceMixin::getInvoiceSupplierControl", url)
			if(result) return result.retour
			return null
		},

		async getInvoiceSupplierControlByTiers(tiers_id, entity_ids) {
			const url = this.constructRoute(Constants.INVOICES_SUPPLIERS_TIERS_OCR_CONTROL, {tiers_id, entity_ids}) + "?licence_key=" + Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("InvoiceMixin::getInvoiceSupplierControlByTiers", url)
			if(result) return result.retour
			return null
		},

		async getInvoiceSupplierChecked(entity_ids, start, end) {
			const url = this.constructRoute(Constants.INVOICES_SUPPLIERS_OCR_LIST_CHECKED, {entity_ids}) + "?licence_key=" + Constants.USER_LICENCE_KEY+"&start="+start+"&end="+end

			const result = await this.$request.request_get_api("InvoiceMixin::getInvoiceSupplierControl", url)
			if(result) return result.retour
			return null
		},

		async getInvoiceSupplierCheckedByTiers(tiers_id, entity_ids, start, end) {
			const url = this.constructRoute(Constants.INVOICES_SUPPLIERS_TIERS_OCR_CHECKED, {tiers_id, entity_ids}) + "?licence_key=" + Constants.USER_LICENCE_KEY+"&start="+start+"&end="+end

			const result = await this.$request.request_get_api("InvoiceMixin::getInvoiceSupplierCheckedByTiers", url)
			if(result) return result.retour
			return null
		},

		async deleteInvoiceSupplier(invoicesupplier_id) {
			const url = this.constructRoute(Constants.INVOICES_SUPPLIERS_OCR_ID_URL, {invoicesupplier_id}) + "?licence_key=" + Constants.USER_LICENCE_KEY

			const result = await this.$request.request_delete_api("InvoiceMixin::deleteInvoiceSupplier", url)
			if(result) return result.retour
			return null
		},

		async updateInvoiceSupplier(invoicesupplier_id, data) {
			const url = this.constructRoute(Constants.INVOICES_SUPPLIERS_OCR_ID_URL, {invoicesupplier_id}) + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("InvoiceMixin::updateInvoiceSupplier", url, data, false)
			if(result) return result.retour
			return null
		},

		getUrlInvoiceSupplierTiers(tiers_id) {
			return tiers_id ? this.constructRoute(Constants.INVOICES_SUPPLIERS_OCR_TIERS_URL, {tiers_id}) + "?licence_key="+Constants.USER_LICENCE_KEY : Constants.INVOICES_SUPPLIERS_OCR_URL + "?licence_key="+Constants.USER_LICENCE_KEY
		},

		async setDuplicateInvoiceSupplier(invoicesupplier_ids) {
			const url = this.constructRoute(Constants.INVOICES_SUPPLIERS_OCR_DUPLICATE_URL, {invoicesupplier_ids}) + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("InvoiceMixin::setDuplicateInvoiceSupplier", url, {}, false)
			if(result) return result.retour
			return null
		},

		async soldeInvoiceSupplier(invoicesupplier_id, amount) {
			const url = this.constructRoute(Constants.INVOICES_SUPPLIERS_OCR_SOLDE_URL, {invoicesupplier_id}) + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("InvoiceMixin::soldeInvoiceSupplier", url, {amount}, false)
			if(result) return result.retour
			return null
		},

		async getDuplicationInvoiceSupplier(invoicesupplier_ids) {
			const url = this.constructRoute(Constants.INVOICES_SUPPLIERS_OCR_DUPLICATE_URL, {invoicesupplier_ids}) + "?licence_key=" + Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("InvoiceMixin::getDuplicationInvoiceSupplier", url)
			if(result) return result.retour
			return null
		},

		async getChargeAccountSupplier(invoicesupplier_id) {
			const url = this.constructRoute(Constants.INVOICES_SUPPLIERS_OCR_CHARGE_URL, {invoicesupplier_id}) + "?licence_key=" + Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("InvoiceMixin::getChargeAccountSupplier", url)
			if(result) return result.retour
			return null
		},

		async saveChargeAccountSupplier(invoicesupplier_id, charges) {
			const url = this.constructRoute(Constants.INVOICES_SUPPLIERS_OCR_CHARGE_URL, {invoicesupplier_id}) + "?licence_key=" + Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("InvoiceMixin::saveChargeAccountSupplier", url, {charges}, false)
			if(result) return result.retour
			return null
		},

		async getChargesSupplierByHorse(horse_id, start, end) {
			const url = this.constructRoute(Constants.INVOICES_SUPPLIERS_OCR_CHARGE_HORSE_URL, {horse_id}) + "?licence_key=" + Constants.USER_LICENCE_KEY+'&start='+start+'&end='+end

			const result = await this.$request.request_get_api("InvoiceMixin::getChargesSupplierByHorse", url)
			if(result) return result.retour
			return null
		},

		loadExportComptableSupplier: async function(invoicesupplier_ids) {
			const url = Constants.INC_SUPPL_EXPORT_COMPTABLE + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("InvoiceMixin::loadExportComptableSupplier", url, {invoicesupplier_ids}, false)
			.catch(error => {
				console.error("InvoiceMixin::loadExportComptableSupplier => ERROR", error)
				this.failureToast("toast.info_save_failed")
				return null
			})

			Object.keys(result).forEach(function (item) {
				Common.csvToFile(result[item]['lines'], "export_comptable_" + item + "." + result[item]['extension'])
			});

        },

		async getChargeAccountSupplierMostUsed(entity_id, tiers_id) {
			const url = this.constructRoute(Constants.INC_SUPPL_ACCOUNT_MOST_USED, {entity_id, tiers_id}) + "?licence_key=" + Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("InvoiceMixin::getChargeAccountSupplierMostUsed", url)
			if(result) return result.retour
			return null
		},

		async setAccountedInvoiceSupplier(invoicesupplier_ids) {
			const url = this.constructRoute(Constants.INVOICES_SUPPLIERS_OCR_ACCOUNTED_URL, {invoicesupplier_ids}) + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("InvoiceMixin::setAccountedInvoiceSupplier", url, null, false)
			.catch(error => {
				console.error("InvoiceMixin::setAccountedInvoiceSupplier => ERROR", error)
				this.failureToast("toast.info_save_failed")
				return null
			})
			return result
		},

		async getExportDES(month, year, entity_ids) {
			const url = this.constructRoute(Constants.EXPORT_DES_URL, {month, year, entity_ids}) + "?licence_key=" + Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("InvoiceMixin::getExportDES", url)
			if(result) return result.retour
			return null
		},

		async getExportDEB(month, year, entity_ids) {
			const url = this.constructRoute(Constants.EXPORT_DEB_URL, {month, year, entity_ids}) + "?licence_key=" + Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("InvoiceMixin::getExportDEB", url)
			if(result) return result.retour
			return null
		},

		async generateExportDES(month, year, entity_ids) {
			const url = this.constructRoute(Constants.GENERATE_EXPORT_DES_URL, {month, year, entity_ids}) + "?licence_key=" + Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("InvoiceMixin::generateExportDES", url)
			if(result) {
				if (window.navigator.msSaveBlob) { // IE
		            const blob = new Blob([result], {type:  `application/vnd.ms-excel;charset=UTF-8;`});
		            window.navigator.msSaveOrOpenBlob(blob, 'export_des.xml')
				} else {
					const encodedUri = `data:xml; charset=UTF-8,${encodeURIComponent('\uFEFF' + result)}`

					const link = document.createElement('a')
					link.setAttribute('href', encodedUri)
					link.setAttribute('target', '_blank');
					link.setAttribute('download', `export_des_${('0' + month).slice(-2)}_${year}.xml`);

					link.click()
					link.remove()
				}
			}
			return null
		},

		async generateExportDEB(month, year, entity_ids) {
			const url = this.constructRoute(Constants.GENERATE_EXPORT_DEB_URL, {month, year, entity_ids}) + "?licence_key=" + Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("InvoiceMixin::generateExportDES", url)
			if(result) return result.retour
			return null
		},
	}
}

export default InvoiceMixin
